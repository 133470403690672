/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from "react";
import Loading from "./Pages/Loader/Loader";
//@ts-ignore
import "aos/dist/aos.css";

const Body = React.lazy(() => import("./Pages/Body/Body"));

// console.disableYellowBox = true;

function App() {
  const [isLoading, setLoading] = useState(true);

  const appendScript = (scriptToAppend: string) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
  };

  const appendLinks = (linkToAppend: string) => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = linkToAppend;
    document.body.appendChild(link);
  };

  const loadScripts = async () => {
    appendScript(
      "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js",
    );
    appendScript(
      "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js",
    );
    appendScript(
      "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js",
    );
    appendLinks(
      "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css",
    );
    let val = await import("aos");
    val.init();
    setLoading(false);
  };

  useEffect(() => {
    loadScripts();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      {isLoading ? <Loading /> : <Body />}
    </Suspense>
  );
}

export default App;
