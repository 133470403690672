import React from "react";
import "./Loader-style.scss";

const AppIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#FEC576" />
    <g clip-path="url(#clip0)">
      <path
        d="M22.0723 21.8125V21.7656L20.3123 18.6813C20.3123 18.6813 19.8998 18.7563 19.4529 19.3891C19.1435 19.825 11.1307 30.5969 11.086 30.6813C10.8454 31.15 11.141 31 11.6257 30.7609C11.6532 30.7609 15.5616 29.0453 15.5616 29.0453C15.5788 29.0172 15.5582 29.0406 15.5823 29.0172C15.5685 28.7781 15.5548 27.7891 15.5479 27.7188C15.5273 27.475 15.6235 27.3953 15.7885 27.3016L18.9716 25.7172C18.9923 25.6797 22.0138 21.8875 22.0723 21.8125V21.8125ZM27.5757 27.4422C28.0329 28.1969 28.2873 28.0656 28.6344 27.8781C28.7444 27.8219 32.6013 25.6469 32.6838 25.5859C32.9588 25.3844 32.6254 24.8031 32.4363 24.4891C32.3641 24.3719 25.386 12.9766 25.3138 12.85C24.9804 12.2781 24.8223 12.2453 23.9938 12.25C23.6432 12.25 20.3226 12.2734 19.9891 12.2781C19.3291 12.2828 18.8582 12.2641 19.3291 13.0703C19.5938 13.5156 27.3798 27.1188 27.5757 27.4422V27.4422ZM32.8248 27.5172C32.7457 27.5031 31.9791 27.2969 31.5185 27.1797C31.5185 27.1797 27.5654 29.5422 27.4794 29.5984C26.9294 29.9406 26.5548 29.4484 26.2179 28.9141L24.2551 25.4453C24.0694 25.4031 22.1788 24.9953 22.0104 25.0094C21.9038 25.0188 21.6804 25.0469 21.5154 25.1453C21.3469 25.2438 16.5241 27.6203 16.3523 27.7094C16.1769 27.8031 15.9604 27.8781 15.9707 28.0656C15.9776 28.1828 16.0394 28.1875 16.1288 28.2297C16.2216 28.2672 26.4723 31.3984 26.7163 31.4688C27.2526 31.6234 28.0398 31.9609 28.5588 31.5484C28.631 31.4922 32.8144 27.9672 32.8832 27.9016C33.0688 27.7141 33.031 27.5828 32.8248 27.5172V27.5172Z"
        fill="#1F2235"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="22"
          height="24"
          fill="white"
          transform="translate(11 10)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Loading = () => {
  // $(window).on("load", () => {
  //     $(".Loading").fadeOut("slow")
  // })

  return (
    <div className="Loading">
      <AppIcon />
      {/* <span className="loader">
                <span className="loader-inner" />
            </span> */}
    </div>
  );
};

export default Loading;
